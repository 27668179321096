@font-face {
    font-family: "michael.markie.co";
    src: url('../fonts/michael.markie.co.eot');
    src: url('../fonts/michael.markie.co.eot?#iefix') format('eot'), url('../fonts/michael.markie.co.woff') format('woff'), url('../fonts/michael.markie.co.ttf') format('truetype'), url('../fonts/michael.markie.co.svg#michael.markie.co') format('svg'); }

@mixin icon-styles {
    font-family: "michael.markie.co";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    // speak: none; // only necessary if not using the private unicode range (firstGlyph option)
    text-decoration: none;
    text-transform: none; }


%icon {
    @include icon-styles; }

@function icon-char($filename) {
    $char: "";

    @if $filename == books {
        $char: "\E001"; }

    @if $filename == briefcase {
        $char: "\E002"; }

    @if $filename == hardware {
        $char: "\E003"; }

    @if $filename == hat {
        $char: "\E004"; }

    @if $filename == pencil {
        $char: "\E005"; }

    @if $filename == speech {
        $char: "\E006"; }

    @return $char; }


@mixin icon($filename, $insert: before, $extend: true) {
    &:#{$insert} {
        @if $extend {
            @extend %icon; }
        @else {
            @include icon-styles; }

        content: icon-char($filename); } }

.icon-books {
    @include icon(books); }
.icon-briefcase {
    @include icon(briefcase); }
.icon-hardware {
    @include icon(hardware); }
.icon-hat {
    @include icon(hat); }
.icon-pencil {
    @include icon(pencil); }
.icon-speech {
    @include icon(speech); }
